import React from "react";
import { Box } from "@mui/material";
const CQooredooLogo = () => {
  return (
    <React.Fragment>
      <Box
        display={{ xs: "block", sm: "none" }}
        component="img"
        alt="ooredoo logo"
        src="images/logos/ooredoo-icons.png"
        width="25px"
      />
      <Box
        display={{ xs: "none", sm: "block" }}
        component="img"
        alt="ooredoo logo"
        src="images/logos/ooredoo.png"
      />
    </React.Fragment>
  );
};

export default CQooredooLogo;
