import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import './CQpersonCard.css';
import { useNavigate } from 'react-router-dom';
import CQbuttonChange from '../buttons/CQbuttonChange';

const InactiveBox = styled(Box)(({ theme }) => ({
  boxShadow: theme.shadows[4],
  borderRadius: '25.5px',
}));

const CQNotActivePersonCard = ({
  display,
  children,
  isOfferActivate = true,
  countChildren,
  openModal = null,
  gsmOffer,
}) => {
  const navigate = useNavigate();
  return (
    <Box
      key={children.id}
      sx={{
        position: 'relative',
        top: { xs: '0px', sm: '-10px' },
      }}
    >
      <Box className="person-card-inactive">
        <InactiveBox>
          <Box className="person-card">
            <Box
              component="img"
              className="image"
              alt={children.fullName}
              src={children?.avatar?.urlPath}
            />
          </Box>

          <Box className="full-name">
            <Typography
              fontSize="1.446rem"
              component="p"
              color="CustomBlue.light"
            >
              {children.fullName}
            </Typography>
            <Typography
              fontSize="1.446rem"
              component="p"
              color="CustomBlue.light"
            >
              {children.level.name}
            </Typography>
          </Box>
        </InactiveBox>
      </Box>
      {gsmOffer?.totalSeats > countChildren && (
        <CQbuttonChange
          onClick={() => (display ? openModal() : navigate('/changeoffer'))}
        >
          تفعيل العرض
        </CQbuttonChange>
      )}
    </Box>
  );
};

export default CQNotActivePersonCard;
