import React, { useState, useEffect } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Navbar from './components/navs/Navbar';
import NewStudent from './components/new-student/NewStudent';
import Subscription from './components/subscriptions/Subscription';
import Confirmation from './components/confirmation/Confirmation';
import ChangeOffer from './components/changeoffer/ChangeOffer';
import Offers from './components/offer/Offers';
import Login from './components/login';
import './index.css';
import { useSelector, useStore } from 'react-redux';
import Toast from './components/Toast';
import { LOADING_STARTED, LOADING_STOPED } from './redux/reducers/loader';
import { getMe } from './actions/getMe';
import { loginByToken } from './actions/login-by-token';
import SimpleBackdrop from './components/loader/Backdrop';

function App() {
  const store = useStore();
  //?token=7de1b3d5-6435-11ed-a9a6-fa163eef2e72
  const connected = localStorage.getItem('connected');
  const loading = useSelector((state) => state.loader.loading);
  const [start, setStart] = useState(false);
  const [userConnected, setUserConnected] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    if (userConnected) {
      let token;
      if (location?.search && location.search.includes('token')) {
        const url = new URL(window.location);

        token = url.searchParams.get('token');

        localStorage.setItem('ValidToken', token);
        loginByToken({ token }, store, navigate);
        setUserConnected(false);
        if (location.pathname === '/subscription') {
          store.dispatch({
            type: LOADING_STOPED,
            payload: {},
          });
        }
      } else {
        getMe(store);
        setUserConnected(false);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      }
      setStart(false);
    } else {
      setUserConnected(false);
      setStart(false);
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
    return () => {};
  }, [connected, loading, store]);

  let app;
  if (start) {
    return <SimpleBackdrop />;
  } else if (!connected || connected === 'false') {
    app = (
      <Routes>
        <Route path="*" element={<Navigate replace to={`/login`} />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    );
  } else {
    app = (
      <Routes>
        <Route path="/new-student" element={<NewStudent />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/offers" element={<Offers />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/changeoffer" element={<ChangeOffer />} />
        <Route path="*" element={<Navigate replace to={`/subscription`} />} />
      </Routes>
    );
  }
  if (loading) {
    return <SimpleBackdrop />;
  }
  return (
    <>
      <Navbar />
      {app}
      <Toast />
    </>
  );
}

export default App;
