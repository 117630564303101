import { Card, styled } from '@mui/material';

const CQcard = styled(Card)(({ theme }) => ({
  borderRadius: '35.5px',
  padding: '30px',
  boxShadow: 'inset 0 0 10px grey',
  border: '5px solid white',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: '15px',
  },
  [theme.breakpoints.up('md')]: {
    width: '80%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '75%',
  },
}));

export default CQcard;
