import { Box } from "@mui/material";
import React from "react";

const CQerror = ({ error }) => {
  return (
    <Box textAlign="center" color="CustomRed.main" mt="1.875rem">
      * {error}
    </Box>
  );
};

export default CQerror;
