import { apiCall } from '../utils/helpers';
import { GET_ME } from '../redux/reducers/auth';
import { LOADING_STOPED } from '../redux/reducers/loader';

export async function getMe(store) {
  try {
    const url = `/parent-details`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    if (data?.parent) {
      localStorage.setItem('connected', true);
      store.dispatch({
        type: GET_ME,
        payload: {
          userProfile: { ...data.parent },
        },
      });
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    } else {
      localStorage.setItem('connected', false);
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
    return data;
  } catch (err) {
    localStorage.setItem('connected', false);
    const { response } = err;
    if (response && response.status === 401) {
      await window.localStorage.clear();
    }
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
  }
  return true;
}
