import React, { useEffect, useState } from 'react';
import { Box, Modal, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CQcard from '../utils/CQcard';
import Layout from '../layout/Layout';
import CQpersonCard from '../utils/cards/CQpersonCard';
import CQbuttonBack from '../utils/buttons/CQbuttonBack';
import CQbuttonBlue from '../utils/buttons/CQbuttonBlue';
import CQbuttonAdd from '../utils/buttons/CQbuttonAdd';
import SubscriptionFrom from './SubscriptionmModal';
import CQtypographyBlue from '../utils/typography/CQtypographyBlue';
import CQtypographyRed from '../utils/typography/CQtypographyRed';
import { useSelector, useStore } from 'react-redux';
import { LOADING_STARTED, LOADING_STOPED } from '../../redux/reducers/loader';
import { getStudents } from '../../actions/get-students';
import SimpleBackdrop from '../loader/Backdrop';
import CQNotActivePersonCard from '../utils/cards/CQNotActivePersonCard';
import { isAndroid, isIOS } from 'react-device-detect';
import toast from '../../utils/toast';
import {
  classQuizDeepLink,
  classQuizWebAppUrl,
  huaweiDeepLink,
  appGalleryUrl,
  classquizIosPageUrl,
} from '../../utils/constants';

const Subscription = () => {
  const store = useStore();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(null);
  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);
  const children = useSelector((state) => state.student.list);
  const userProfile = useSelector((state) => state.auth.userProfile);

  useEffect(() => {
    if (localStorage.getItem('ValidToken')) {
      setToken(localStorage.getItem('ValidToken'));
    }
  }, [token]);

  const onOpenAppClick = () => {
    const hasChildren = children?.length > 0;

    if (!hasChildren) {
      navigate('/new-student');
      return;
    }

    const isHuawei = navigator.userAgent.match(/Huawei/i);

    if (isHuawei) {
      setTimeout(() => {
        window.open(appGalleryUrl, '_blank');
      }, 700);
      window.location = `intent://open-app?${token}#${huaweiDeepLink}`;
      return;
    }

    if (isAndroid) {
      window.location = `intent://open-app?${token}#${classQuizDeepLink}`;
      return;
    }
    if (isIOS) {
      const appStoreUrl = 'https://apps.apple.com/tn/app/class-quiz/id1670110286?l=fr-FR';

      window.location = `classquiz://?${token}`;
      setTimeout(() => {
        window.location = appStoreUrl;
      }, 1500); 
      return;
    }
  
    window.open(`${classQuizWebAppUrl}?${token}`, '_blank');
  };
  const handleClose = () => setOpen(false);
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    getStudents(store);
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
  }, []);
  const addStudent = () => {
    setData(null);
    setIndex(null);
    setOpen(true);
  };

  function order(a, b) {
    return a.id > b.id ? -1 : a.id < b.id ? 1 : 0;
  }

  return (
    <Layout>
      <SimpleBackdrop />
      <React.Fragment>
        <Box className="center-align-box">
          <CQcard>
            {userProfile?.gsmOffer ? (
              <Box textAlign="center">
                <CQtypographyBlue component="span">
                  العرض الحالي المفعل:
                </CQtypographyBlue>
                <CQtypographyRed component="span">
                  &nbsp; {userProfile?.gsmOffer?.packType?.title}
                </CQtypographyRed>
              </Box>
            ) : (
              <Box textAlign="center">
                <CQtypographyBlue component="span">
                  لا يوجد عرض مفعل في الوقت الحالي
                </CQtypographyBlue>
              </Box>
            )}
            <Box display={{ xs: 'block', sm: 'none' }} my={2}>
              <Stack spacing={1}>
                {/*<CQbuttonBlue onClick={() => navigate('/changeoffer')}>
                  تغيير العرض
                </CQbuttonBlue>*/}
                <CQbuttonBlue
                  onClick={onOpenAppClick}
                  sx={{
                    paddingX: '20px',
                  }}
                >
                  الدخول لمحتوى كلاس كويز
                </CQbuttonBlue>
              </Stack>
            </Box>
            <Stack
              marginBottom="4em"
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="space-around"
              alignItems={{ xs: 'center', sm: 'end' }}
              spacing={3}
              mt={2}
            >
              {children.length > 0 &&
                children
                  .slice(0, 3)
                  .map((item) =>
                    item.subscription.isActive ? (
                      <CQpersonCard
                        key={item.id}
                        children={item}
                        fullName="هشام عنيمي "
                        grade="firstGrade"
                        src="https://unsplash.it/640/425"
                        type="button"
                      />
                    ) : (
                      <CQNotActivePersonCard
                        key={item.id}
                        children={item}
                        fullName="هشام عنيمي "
                        grade="firstGrade"
                        src="https://unsplash.it/640/425"
                        type="button"
                        display={false}
                        countChildren={children.length}
                        gsmOffer={userProfile?.gsmOffer}
                      />
                    ),
                  )
                  .sort(order)}

              {userProfile?.gsmOffer?.totalSeats > children.length && (
                <CQbuttonAdd type="button" onClick={addStudent} />
              )}
            </Stack>
            <Box display={{ xs: 'none', sm: 'unset' }}>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-around' }}
                my={2}
              >
                {/*<CQbuttonBlue onClick={() => navigate('/changeoffer')}>
                  تغيير العرض
                </CQbuttonBlue>*/}
                <CQbuttonBlue
                  onClick={onOpenAppClick}
                  sx={{
                    paddingX: '20px',
                  }}
                >
                  الدخول لمحتوى كلاس كويز
                </CQbuttonBlue>
              </Box>
            </Box>
          </CQcard>
        </Box>
        <CQbuttonBack />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
        >
          <SubscriptionFrom
            index={index}
            data={data}
            handleClose={handleClose}
          />
        </Modal>
      </React.Fragment>
    </Layout>
  );
};

export default Subscription;
