import React, { useState } from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { Box, Stack, Typography } from '@mui/material';
import * as Yup from 'yup';
import CQbuttonGreen from '../utils/buttons/CQbuttonGreen';
import CQerror from '../utils/CQerror';
import toast from '../../utils/toast';
import { confirmPayment } from '../../actions/confirm-payment';
import { useSelector, useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CQcard from '../utils/CQcard';
import CQtypographyBlue from '../utils/typography/CQtypographyBlue';

const initialValues = {
  verificationCode: '',
};
const validationSchema = Yup.object({
  verificationCode: Yup.number()
    .min(1000, 'رمز التثبيت متكون من 4 أرقام')
    .max(9999, 'رمز التثبيت متكون من 4 أرقام')
    .required('الرجاء إدخال الرمز المتحصل عليه في الرسالة القصيرة'),
});

const ChangeOfferFormCancel = ({ handleCancelOffer }) => {
  const store = useStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const userProfile = useSelector((state) => state.auth.userProfile);
  const onSubmit = async (values) => {
    try {
      setLoading(true);
      values.phone = userProfile.phone;
      const result = await confirmPayment(values, store);
      if (result.status === 200) {
        setLoading(false);
        toast.showSuccess(store, result?.message || 'error');
        navigate('/changeoffer');
      } else {
        setLoading(false);
        toast.showError(store, result?.message || 'error');
      }
    } catch (error) {
      setLoading(false);
      if (error?.response)
        toast.showError(store, error?.response?.data?.message || 'error');
    }
  };

  return (
    <Box className="center-modal" sx={{ width: '150 %' }}>
      <CQcard>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CQtypographyBlue component="span">
                العرض الحالي المفعل:
              </CQtypographyBlue>
            </Box>
            <Box textAlign="center" mt={4}>
              <CQbuttonGreen
                onClick={() => handleCancelOffer()}
                type="button"
                variant="contained"
                disabled={loading}
              >
                رجوع
              </CQbuttonGreen>
            </Box>
            <ErrorMessage
              name="verificationCode"
              render={(error) => <CQerror error={error} />}
            />
          </Form>
        </Formik>
      </CQcard>
    </Box>
  );
};

export default ChangeOfferFormCancel;
