import {Box, Stack, Typography} from "@mui/material";
import React from "react";
import CQcard from "../utils/CQcard";
import Layout from "../layout/Layout";
import NewStudentForm from "./NewStudentForm";
import CQbuttonBack from "../utils/buttons/CQbuttonBack";
import CQtypographyBlue from "../utils/typography/CQtypographyBlue";
import CQtypographyRed from "../utils/typography/CQtypographyRed";
import {useSelector} from "react-redux";

const NewStudent = () => {
    const userProfile = useSelector((state) => state.auth.userProfile);

    return (
        <Layout>
            <Box className="center-align-box">
                <CQcard>
                    <Box textAlign="center">
                        {userProfile?.gsmOffer ? (
                            <Box textAlign="center">
                                <CQtypographyBlue component="span">
                                    العرض الحالي المفعل:
                                </CQtypographyBlue>
                                <CQtypographyRed component="span">
                                    &nbsp;
                                    {userProfile?.gsmOffer?.packType?.title}
                                </CQtypographyRed>
                            </Box>
                        ) : (
                            <Box textAlign="center">
                                <CQtypographyBlue component="span">
                                    لا يوجد عرض مفعل في الوقت الحالي
                                </CQtypographyBlue>
                            </Box>
                        )}


                    </Box>
                    <Box
                        sx={{
                            marginTop: "2.5rem",
                            textAlign: "center",
                        }}
                    >
                        <Typography
                            fontSize={{xs: "24px", sm: "1.875rem"}}
                            component="span"
                            color="CustomBlue.light"
                        >
                            مرحبا بك، تفضّل بإضافة ملف تلميذ جديد.
                        </Typography>
                    </Box>
                    <NewStudentForm/>
                </CQcard>
            </Box>
            <CQbuttonBack/>
        </Layout>
    );
};

export default NewStudent;
