import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import SubscriptionForm from './SubscriptionForm';
import CQcard from '../utils/CQcard';
import CQtypographyRed from '../utils/typography/CQtypographyRed';
import { LOADING_STARTED, LOADING_STOPED } from '../../redux/reducers/loader';
import { getStudents } from '../../actions/get-students';
import { getLevels } from '../../actions/get-levels';
import { useSelector, useStore } from 'react-redux';
const SubscriptionFrom = ({ handleClose, index, data }) => {
  const store = useStore();
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    getLevels(store);
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
  }, []);
  const levels = useSelector((state) => state.level.list);
  return (
    <Box className="center-modal" sx={{ width: '150 %' }}>
      <CQcard>
        <CQtypographyRed textAlign="center" color="#e74e1b">
          إضافة تلميذ جديد
        </CQtypographyRed>
        <SubscriptionForm
          index={index}
          data={data}
          levels={levels}
          handleClose={handleClose}
        />
      </CQcard>
    </Box>
  );
};

export default SubscriptionFrom;
