import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import Layout from '../layout/Layout';
import CQcard from '../utils/CQcard';
import CQbuttonBack from '../utils/buttons/CQbuttonBack';
import OffersForm from './OffersForm';
import CQtypographyRed from '../utils/typography/CQtypographyRed';
import './offers.css';
import { LOADING_STARTED, LOADING_STOPED } from '../../redux/reducers/loader';
import { getOffers } from '../../actions/get-offers';
import { useSelector, useStore } from 'react-redux';
import SimpleBackdrop from '../loader/Backdrop';

const Offers = () => {
  const store = useStore();
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    getOffers(store);
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
  }, []);
  const offers = useSelector((state) => state.offer.list);
  const loading = useSelector((state) => state.loader.loading);
  return (
    <Layout>
      <SimpleBackdrop load={loading} />
      <Box className="center-align-box">
        <CQcard>
          <Box textAlign="center">
            <CQtypographyRed color="CustomRed.main" marginRight="10px">
              العروض المتاحة:
            </CQtypographyRed>
            {offers.length > 0 && <OffersForm offers={offers} />}
          </Box>
        </CQcard>
      </Box>
      <CQbuttonBack />
    </Layout>
  );
};

export default Offers;
