import React, { useState } from 'react';
import { useStore } from 'react-redux';
import toastService from '../../utils/toast';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function ToastComponent({
  id = '',
  key = '',
  text1 = 'title',
  text2 = 'message',
  position = 'bottom',
  visibilityTime = 7000,
  type = 'success',
  index = '',
}) {
  const [closed, setClosed] = useState(true);
  let store = useStore();

  if (type !== 'error') {
    setTimeout(() => {
      if (!closed) {
        //setClosed(true);
        //fadeOut();
        handleDeleteToast();
      }
    }, visibilityTime);
  }

  const handleDeleteToast = () => {
    try {
      toastService.deleteToast(store, {
        id,
        text1,
        text2,
        type,
      });
      setClosed(false);
    } catch (error) {
      // propper handeling required, with appLogger.error(error); OR errorsHandler(error);
      // console.error("error ->", error);
      //errorsHandler(error);
    }
  };
  if (typeof text1 === 'string' && typeof text2 === 'string')
    return (
      <Snackbar
        key={key}
        open={closed}
        autoHideDuration={7000}
        onClose={handleDeleteToast}
      >
        <Alert onClose={handleDeleteToast} severity={type}>
          {text2 !== '' && text2}
        </Alert>
      </Snackbar>
    );
  else return <></>;
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default ToastComponent;
