import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useSelector, useStore } from 'react-redux';
import { LOADING_STOPED } from '../../redux/reducers/loader';

export default function SimpleBackdrop({ load }) {
  const store = useStore();
  const loading = useSelector((state) => state.loader.loading);
  const [open, setOpen] = React.useState(true);
  return (
    <div>
      {/*<Button onClick={handleToggle}>Show backdrop</Button>*/}
      <Backdrop
        sx={{ backgroundColor : 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() =>
          store.dispatch({
            type: LOADING_STOPED,
            payload: {},
          })
        }
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
}
