import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import CQcard from '../utils/CQcard';
import Layout from '../layout/Layout';
import CQbuttonBack from '../utils/buttons/CQbuttonBack';
import FormLoginByToken from './form';

const LoginByToken = () => {
  return (
    <Layout>
      <Box className="center-align-box">
        <CQcard>
          <Box
            sx={{
              marginTop: '2.5rem',
              textAlign: 'center',
            }}
          >
            <Typography
              fontSize={{ xs: '24px', sm: '1.875rem' }}
              component="span"
              color="CustomBlue.light"
            >
              مرحبا بك، تفضّل بإضافة الرمز.
            </Typography>
          </Box>
          <FormLoginByToken />
        </CQcard>
      </Box>
      <CQbuttonBack />
    </Layout>
  );
};

export default LoginByToken;
