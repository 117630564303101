import React, { useState } from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { Box } from '@mui/material';
import * as Yup from 'yup';
import CQbox from '../utils/CQbox';
import CQbuttonGreen from '../utils/buttons/CQbuttonGreen';
import CQerror from '../utils/CQerror';
import toast from '../../utils/toast';
import { confirmPayment } from '../../actions/confirm-payment';
import { useSelector, useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const initialValues = {
  verificationCode: '',
};
const validationSchema = Yup.object({
  verificationCode: Yup.number()
    .min(1000, 'رمز التثبيت متكون من 4 أرقام')
    .max(9999, 'رمز التثبيت متكون من 4 أرقام')
    .required('الرجاء إدخال الرمز المتحصل عليه في الرسالة القصيرة'),
});

const ConfirmationForm = () => {
  const store = useStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const userProfile = useSelector((state) => state.auth.userProfile);
  const onSubmit = async (values) => {
    try {
      setLoading(true);
      values.phone = userProfile.phone;
      const result = await confirmPayment(values, store);
      if (result.status === 200) {
        setLoading(false);
        toast.showSuccess(store, result?.message || 'error');
        navigate('/changeoffer');
      } else {
        setLoading(false);
        toast.showError(store, result?.message || 'error');
      }
    } catch (error) {
      setLoading(false);
      if (error?.response)
        toast.showError(store, error?.response?.data?.message || 'error');
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CQbox sx={{ maxWidth: '500px' }}>
            <Field
              type="number"
              name="verificationCode"
              placeholder="أدخل الرمز"
              className="form-control"
              style={{ textAlign: 'center' }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              min="1000"
              max="9999"
            />
          </CQbox>
        </Box>
        <Box textAlign="center" mt={4}>
          <CQbuttonGreen
            className={loading ? 'buttonload' : ''}
            type="submit"
            variant="contained"
            disabled={loading}
          >
            تثبيت الرمز
            {loading ? <i className="fa fa-refresh fa-spin"></i> : ''}
          </CQbuttonGreen>
        </Box>
        <ErrorMessage
          name="code"
          render={(error) => <CQerror error={error} />}
        />
      </Form>
    </Formik>
  );
};

export default ConfirmationForm;
