import { Button, styled } from "@mui/material";

const CQbuttonRed = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusPill,
  backgroundImage: `linear-gradient(to bottom,${theme.palette.CustomRed.light},${theme.palette.CustomRed.dark})`,
  fontSize: theme.shape.MainSize + " " + "!important",
  color: theme.palette.common.white,
  minWidth: "11rem",
  "&:hover": {
    backgroundColor: theme.palette.CustomRed.dark,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "24px !important",
    minWidth: "8rem",
  },
}));

export default CQbuttonRed;
