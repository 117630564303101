import React from "react";

const CQlabel = ({ id, children, ...props }) => {
  return (
    <label htmlFor={id} {...props}>
      {children}
    </label>
  );
};

export default CQlabel;
