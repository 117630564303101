import toast from '../utils/toast';
import { apiCall } from '../utils/helpers';
import { ADD_STUDENT, UPDATE_STUDENT } from '../redux/reducers/student';

export async function createUpdateStudent(id, values, store = {}, index) {
  const { data } = await apiCall({
    method: id ? 'patch' : 'post',
    url: id ? `/students/${id}` : `/students`,
    data: values,
  });
  if (data?.id) {
    if (id)
      store.dispatch({
        type: UPDATE_STUDENT,
        payload: {
          data,
          index,
        },
      });
    else
      store.dispatch({
        type: ADD_STUDENT,
        payload: {
          data,
        },
      });
    toast.showSuccess(store, data?.message);
  } else {
    const message = data?.message ? data?.message : 'error occured';
    toast.showError(store, message);
  }
  return data;
}
