import React, { useState } from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';
import CQcard from '../utils/CQcard';
import CQbuttonGreen from '../utils/buttons/CQbuttonGreen';
import CQbuttonRed from '../utils/buttons/CQbuttonRed';
import { LOADING_STARTED, LOADING_STOPED } from '../../redux/reducers/loader';
import toast from '../../utils/toast';
import { useStore } from 'react-redux';
import { activatePayment } from '../../actions/activate-payment';

const ChangeOfferModal = ({ handleCloseChangeOffer, studentId }) => {
  const store = useStore();
  const [loading, setLoading] = useState(false);
  const handelClickButton = (value) => {
    handleCloseChangeOffer();
  };
  const onSubmit = async () => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    try {
      setLoading(true);
      const result = await activatePayment([studentId], store);
      if (result.id) {
        setLoading(false);
        handleCloseChangeOffer();
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      }
    } catch (error) {
      setLoading(false);
      if (error?.response)
        toast.showError(store, error?.response?.data?.error || 'error');
    }
  };

  return (
    <Box className="center-modal-offer">
      <CQcard>
        <Box textAlign="center">
          <Typography
            id="modal-modal-title"
            fontSize="2rem"
            component="span"
            color="CustomBlue.main"
            marginRight="10px"
          >
            هل تود تفعيل العرض لهذا الحساب ؟
          </Typography>
        </Box>
        <Stack
          display={'flex'}
          flexDirection="row"
          justifyContent="space-around"
          sx={{ marginTop: '5rem' }}
        >
          <CQbuttonGreen
            className={loading ? 'buttonload' : ''}
            sx={{ color: 'white', fontSize: '1rem' }}
            onClick={() => onSubmit()}
            disabled={loading}
          >
            نعم
            {loading ? <i className="fa fa-refresh fa-spin"></i> : ''}
          </CQbuttonGreen>
          <CQbuttonRed
            sx={{ color: 'white', fontSize: '1rem' }}
            onClick={() => handelClickButton(false)}
          >
            لا
          </CQbuttonRed>
        </Stack>
      </CQcard>
    </Box>
  );
};

export default ChangeOfferModal;
