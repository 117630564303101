import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography, Modal, Button } from '@mui/material';
import CQcard from '../utils/CQcard';
import Layout from '../layout/Layout';
import CQpersonCard from '../utils/cards/CQpersonCard';
import CQbuttonBack from '../utils/buttons/CQbuttonBack';
import CQbuttonAdd from '../utils/buttons/CQbuttonAdd';
import CQbox from '../utils/CQbox';
import CQbuttonGreen from '../utils/buttons/CQbuttonGreen';
import CQtypographyRed from '../utils/typography/CQtypographyRed';
import CQtypographyBlue from '../utils/typography/CQtypographyBlue';
import { useSelector, useStore } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOADING_STARTED, LOADING_STOPED } from '../../redux/reducers/loader';
import { getStudents } from '../../actions/get-students';
import { getOffers } from '../../actions/get-offers';
import SubscriptionFrom from '../subscriptions/SubscriptionmModal';
import CQNotActivePersonCard from '../utils/cards/CQNotActivePersonCard';
import ChangeOfferModal from './ChangeOfferModal';
import SimpleBackdrop from '../loader/Backdrop';
import ChangeOfferFormCancel from './ChangeOfferFormCancel';

const style = {
  justifyContent: 'space-between',
  width: '100%',
  paddingLeft: '35px',
  paddingRight: '35px',
};

const ChangeOffer = () => {
  const [openAddStudent, setOpenAddStudent] = useState(false);
  const [openChangeOffer, setOpenChangeOffer] = useState(false);
  const [studentId, setStudentId] = useState(null);
  const [openCancelOffer, setOpenCancelOffer] = useState(false);
  const handleCloseStudent = () => setOpenAddStudent(false);
  const handleCloseChangeOffer = () => setOpenChangeOffer(false);
  const handleCancelOffer = () => setOpenCancelOffer(false);
  const changeOffer = () => {
    setOpenChangeOffer(true);
  };
  const addStudent = () => {
    setData(null);
    setIndex(null);
    setOpenAddStudent(true);
  };
  const store = useStore();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(null);
  const [data, setData] = useState(null);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    getStudents(store);
    getOffers(store);
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
  }, []);
  function order(a, b) {
    return a.id > b.id ? -1 : a.id < b.id ? 1 : 0;
  }
  const offers = useSelector((state) => state.offer.list);
  const children = useSelector((state) => state.student.list);
  const loading = useSelector((state) => state.loader.loading);
  const userProfile = useSelector((state) => state.auth.userProfile);
  return (
    <Layout>
      <SimpleBackdrop />
      <React.Fragment>
        <Box className="center-align-box">
          <CQcard>
            {userProfile?.gsmOffer ? (
              <>
                <Box textAlign="center">
                  <CQtypographyBlue component="span">
                    العرض الحالي المفعل:
                  </CQtypographyBlue>
                  <CQtypographyRed component="span">
                    &nbsp; إشتراك يومي لطفل
                  </CQtypographyRed>
                </Box>
                <Box display={{ xs: 'block', sm: 'none' }} my={2}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <CQbuttonGreen>تغيير العرض</CQbuttonGreen>
                  </Box>
                </Box>
                <CQbox>
                  <Stack direction="row" className="center-stack">
                    <CQtypographyBlue
                      component="p"
                      sx={{
                        color: '#52b9e9',
                        fontSize: { xs: '18px', sm: '1.875rem' },
                      }}
                    >
                      عدد العروض المتبقية الغير مفعلة:
                    </CQtypographyBlue>
                    <CQtypographyBlue
                      component="p"
                      color="CustomBlue.main"
                      sx={{ fontSize: { xs: '18px', sm: '1.875rem' } }}
                    >
                      1
                    </CQtypographyBlue>
                  </Stack>
                </CQbox>
              </>
            ) : (
              ''
            )}

            <CQtypographyRed textAlign="center" mt={2}>
              *قم بإضافة تلميذ لتفعيل العروض المتبقية.
            </CQtypographyRed>

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="space-around"
              alignItems={{ xs: 'center', sm: 'end' }}
              spacing={3}
              mt={2}
            >
              {children.length > 0 &&
                children
                  .slice(0, 2)
                  .map((item, index) =>
                    item.subscription.isActive ? (
                      <CQpersonCard
                        key={item.id}
                        children={item}
                        fullName="هشام عنيمي "
                        grade="firstGrade"
                        src="https://unsplash.it/640/425"
                        type="button"
                      />
                    ) : (
                      <CQNotActivePersonCard
                        key={item.id}
                        children={item}
                        fullName="هشام عنيمي "
                        grade="firstGrade"
                        src="https://unsplash.it/640/425"
                        type="button"
                        display={true}
                        setStudentId={setStudentId}
                        countChildren={children.length}
                        gsmOffer={userProfile?.gsmOffer}
                        openModal={() => {
                          setStudentId(item.id);
                          setOpenChangeOffer(true);
                        }}
                      />
                    ),
                  )
                  .sort(order)}
              {userProfile?.gsmOffer > children.length && (
                <CQbuttonAdd type="button" onClick={addStudent} />
              )}
            </Stack>
            <Box display={{ xs: 'none', sm: 'unset' }}>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-around' }}
                mt={6}
              >
                <CQbuttonGreen onClick={() => navigate('/offers')}>
                  تغيير العرض
                </CQbuttonGreen>
              </Box>
            </Box>
            <CQtypographyRed
              onClick={() => setOpenCancelOffer(true)}
              textAlign="center"
              mt={2}
            >
              إلغاء الإشتراك
            </CQtypographyRed>
            {userProfile?.gsmOffer ? (
              <>
                {' '}
                <Box display={{ xs: 'none', sm: 'unset' }}>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-around' }}
                    mt={6}
                  >
                    <CQbuttonGreen onClick={() => navigate('/offers')}>
                      تغيير العرض
                    </CQbuttonGreen>
                  </Box>
                </Box>
                <CQtypographyRed
                  onClick={() => setOpenCancelOffer(true)}
                  textAlign="center"
                  mt={2}
                >
                  إلغاء الإشتراك
                </CQtypographyRed>
              </>
            ) : (
              <Box display={{ xs: 'none', sm: 'unset' }}>
                {' '}
                <Box
                  sx={{ display: 'flex', justifyContent: 'space-around' }}
                  mt={6}
                ></Box>
              </Box>
            )}
          </CQcard>
        </Box>
        <CQbuttonBack />
        <Modal
          open={openCancelOffer}
          onClose={handleCancelOffer}
          aria-labelledby="modal-modal-title"
        >
          <React.Fragment>
            <ChangeOfferFormCancel handleCancelOffer={handleCancelOffer} />
          </React.Fragment>
        </Modal>
        <Modal
          open={openChangeOffer}
          onClose={handleCloseChangeOffer}
          aria-labelledby="modal-modal-title"
        >
          <React.Fragment>
            <ChangeOfferModal
              studentId={studentId}
              handleCloseChangeOffer={handleCloseChangeOffer}
            />
          </React.Fragment>
        </Modal>
        <Modal
          open={openAddStudent}
          onClose={handleCloseStudent}
          aria-labelledby="modal-modal-title"
        >
          <SubscriptionFrom
            index={index}
            data={data}
            handleClose={handleCloseStudent}
          />
        </Modal>
      </React.Fragment>
    </Layout>
  );
};

export default ChangeOffer;
