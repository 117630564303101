import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  Avatar,
} from '@mui/material';
import './Navbar.css';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import { useStore } from 'react-redux';
import { RESET_SETTINGS } from '../../redux/reducers/auth';

const Navbar = () => {
  const store = useStore();
  const connected = localStorage.getItem('connected');
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <AppBar position="sticky" color="inherit">
      <Container>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Avatar
            style={{ cursor: 'pointer' }}
            onClick={handleOpenUserMenu}
            alt="فلان بن فولان "
          />
          {connected ? (
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                key="Mes informations"
                onClick={() => {
                  localStorage.clear();
                  store.dispatch({
                    type: RESET_SETTINGS,
                    payload: {},
                  });
                  setAnchorElUser(null);
                  window.location.href = '/';
                }}
              >
                <Typography textAlign="center">
                  <LogoutIcon /> تسجيل خروج{''}
                </Typography>
              </MenuItem>
            </Menu>
          ) : (
            ''
          )}

          {/*<Typography
            color="CustomBlue.main"
            sx={{ fontSize: { xs: '18px', sm: '1.875rem' } }}
          >
            اهلا بعودتك .......
          </Typography>*/}
          <Link to="/">
            <Box
              className="logo"
              component="img"
              maxWidth="100%"
              height="auto"
              src="images/logos/logo.png"
              alt="Class Quiz"
            />
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
