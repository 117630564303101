import axios from 'axios';
// import { isAndroid, isIOS } from 'react-device-detect';
import { ADD_CONNECTION_PROPS } from '../redux/reducers/auth';
// import toast from '../utils/toast';

export async function loginByToken(values, store = {} /*, navigate */) {
  const { data } = await axios.post(
    `${process.env.REACT_APP_PUBLIC_URL}/check-parent`,
    values,
  );

  if (data.packStatus?.toLowerCase() === 'cancel' || data.status !== 200) {
    window.location.replace('https://gateway.eklectic.tn/LP/32/ClassQuiz/');
    return;
  }

  if (data.status === 200) {
    localStorage.setItem('token', data?.token.token);
    localStorage.setItem('connected', true);

    store.dispatch({
      type: ADD_CONNECTION_PROPS,
      payload: {
        userProfile: data?.user || {},
        children: (data?.children && data?.children.length) || 0,
        token: data?.token.token || {},
        connected: true,
      },
    });

    if (data?.children?.length > 0) {
      /*if (isAndroid) {
        document.location.href =
          `intent://open-app?${data?.token.token}#Intent;scheme=classquiz.tn;package=com.Envast.KidsQuiz;end`;
      } else if (isIOS) {
        toast.showInfo(store, 'هذا الإصدار غير متوافق مع iOS');
      } else {*/
      document.location.href =
        'https://mobilepayment.classquiz.tn/subscription';
      /*}*/
    } else {
      document.location.href = 'https://mobilepayment.classquiz.tn/new-student';
    }
  }
  return data;
}
