import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import CQcard from "../utils/CQcard";
import Layout from "../layout/Layout";
import CQbuttonBack from "../utils/buttons/CQbuttonBack";
import ConfirmationForm from "./ConfirmationForm";

const Confirmation = () => {
  return (
    <Layout>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Stack
          direction="column"
          justifyContent="space-around"
          alignItems="center"
          spacing={0}
          sx={{
            height: "100vh",
          }}
          width="50%"
        >
          <CQcard sx={{ minWidth: "80%" }}>
            <Box textAlign="center">
              <Typography
                fontSize="2rem"
                component="span"
                color="CustomGreen.dark"
              >
                تأكيد العرض
              </Typography>
            </Box>
            <ConfirmationForm />
          </CQcard>
          <CQbuttonBack />
        </Stack>
      </Box>
    </Layout>
  );
};

export default Confirmation;
