import { styled, Box } from "@mui/material";

const CQbox = styled(Box)(({ theme }) => ({
  borderStyle: "solid",
  border: `double 3px transparent`,
  backgroundImage:
    "linear-gradient(white,white),linear-gradient(to bottom, #52b9e9, #0b7bb6)",
  backgroundOrigin: "border-box",
  backgroundClip: "content-box, border-box",
  color: theme.palette.CustomBlue.light,
  display: "flex",
  justifyItems: "center",
  borderRadius: theme.shape.borderRadiusPill,
  height: "4rem",
  marginTop: 16,
  alignItems: "center",
  boxShadow: theme.shadows[4],
  fontSize: theme.shape.MainSize,
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
    height: "2.5rem",
  },
}));

export default CQbox;
