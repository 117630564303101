import { Button, styled, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const BackButton = styled(Button)(({ theme }) => ({
  borderRadius: "25px",
  backgroundImage: `linear-gradient(to bottom,${theme.palette.CustomOrange.main},${theme.palette.CustomOrange.dark})`,
  fontSize: theme.shape.MainSize,
  minWidth: "10rem",
  color: theme.palette.common.white,
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "24px !important",
    minWidth: "8rem",
  },
}));

const CQbuttonBack = () => {
  const navigate = useNavigate();
  const backToPerviousPage = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: { xs: "center", md: "flex-end" },
        marginTop: "32px",
      }}
    >
      <BackButton onClick={backToPerviousPage}>
        <Typography fontSize="1.875rem" marginBottom="10px" component="span">
          رجوع
        </Typography>
        <Box
          component="img"
          width={{ xs: "40px", sm: "50px" }}
          src="images/assets/arrow.png"
          alt="back arrow"
        />
      </BackButton>
    </Box>
  );
};
export default CQbuttonBack;
