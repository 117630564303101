import { Button, styled, Typography, Box } from '@mui/material';

const ButtonAdd = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  minHeight: 0,
  minWidth: 0,
  borderRadius: '25.5px',
  boxShadow: theme.shadows[4],
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px !important',
    minWidth: '8rem',
  },
  height: '295px',
  width: '231.6px',
  marginBottom: '10px',
}));

const CQbuttonAdd = (props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      }}
    >
      <ButtonAdd variant="text" {...props}>
        <Box
          component="img"
          sx={{
            height: '295px',
          }}
          src="images/assets/add.png"
          alt="add icons"
        />
        <Typography
          fontSize="1.446rem"
          component="span"
          color="CustomBlue.light"
          sx={{
            height: '85px',
            paddingTop: '23px',
          }}
        >
          {'إضافة تلميذ (ة)'}
        </Typography>
      </ButtonAdd>
    </Box>
  );
};
export default CQbuttonAdd;
