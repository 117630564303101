import axios from 'axios';
import { ADD_CONNECTION_PROPS } from '../redux/reducers/auth';

export async function login(values, store = {}) {
  const { data } = await axios.post(
    `${process.env.REACT_APP_PUBLIC_URL}/auth/login`,
    values,
  );
  if (data?.token) {
    localStorage.setItem('token', data?.token.token);
    localStorage.setItem('connected', true);
    store.dispatch({
      type: ADD_CONNECTION_PROPS,
      payload: {
        userProfile: data?.user || {},
        token: data?.token.token || {},
        children: (data?.children && data?.children.length > 0) || 0,
        connected: true,
      },
    });
  }
  return data;
}
