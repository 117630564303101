import { apiCall } from '../utils/helpers';
import { GET_LIST_LEVEL } from '../redux/reducers/level';

export async function getLevels(store) {
  const url = `/levels`;
  const { data } = await apiCall({
    method: 'get',
    url,
  });

  if (data?.length > 0) {
    store.dispatch({
      type: GET_LIST_LEVEL,
      payload: {
        data: data,
      },
    });
  } else
    store.dispatch({
      type: GET_LIST_LEVEL,
      payload: {
        data: [],
      },
    });
  return data;
}
