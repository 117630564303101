import React, { useState } from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { Box, Stack } from '@mui/material';
import CQlabel from '../utils/CQlabel';
import CQbox from '../utils/CQbox';
import CQbuttonGreen from '../utils/buttons/CQbuttonGreen';
import * as Yup from 'yup';
import CQerror from '../utils/CQerror';
import { useNavigate } from 'react-router-dom';
import { loginByToken } from '../../actions/login-by-token';
import toast from '../../utils/toast';
import { useStore } from 'react-redux';
import { LOADING_STARTED, LOADING_STOPED } from '../../redux/reducers/loader';
import { login } from '../../actions/login';

const FormLoginByToken = () => {
  const navigate = useNavigate();
  const store = useStore();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (values) => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    try {
      setLoading(true);
      const result = await login(values, store);
      if (result.token) {
        setLoading(false);
        navigate('/subscription');
      }
    } catch (error) {
      setLoading(false);
      if (error?.response)
        toast.showError(store, error?.response?.message || 'error');
    }
  };
  const initialValues = {
    password: '',
    username: '',
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .typeError('يجب أن يكون رقم الهاتف صالحًا')
      .matches(/^\d{0,10}(\.\d{0,2}){0,1}$/, 'يجب أن يكون رقم الهاتف  صالحًا')
      .required('الرجاء إدخال رقم الهاتف'),
    password: Yup.string()
      .min(3, ({ min }) => '3 أحرف على الأقل')
      .matches(/^\d{0,10}(\.\d{0,2}){0,1}$/, 'كلمه السر')
      .required('الرجاء إدخال كلمه السر'),
  });
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <CQbox>
          <Stack direction="row" className="center-stack">
            <CQlabel htmlFor="fullName" className="label-center">
              الهاتف :
            </CQlabel>
            <Field
              //style={{ width: '70%' }}
              type="text"
              name="username"
              id="username"
              placeholder="الهاتف"
              className="form-control"
            />
          </Stack>
        </CQbox>
        <CQbox>
          <Stack direction="row" className="center-stack">
            <CQlabel htmlFor="fullName" className="label-center">
              كلمه السر :
            </CQlabel>
            <Field
              //style={{ width: '70%' }}
              type="password"
              name="password"
              id="password"
              placeholder="************"
              className="form-control"
            />
          </Stack>
        </CQbox>
        <Box textAlign="center" mt={4}>
          <CQbuttonGreen
            className={loading ? 'buttonload' : ''}
            type="submit"
            variant="contained"
            disabled={loading}
          >
            موافق
            {loading ? <i className="fa fa-refresh fa-spin"></i> : ''}
          </CQbuttonGreen>
        </Box>
        <ErrorMessage
          name="username"
          render={(error) => <CQerror error={error} />}
        />
        <ErrorMessage
          name="password"
          render={(error) => <CQerror error={error} />}
        />
      </Form>
    </Formik>
  );
};

export default FormLoginByToken;
