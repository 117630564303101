import { apiCall } from '../utils/helpers';
import { GET_LIST_OFFER } from '../redux/reducers/offer';

export async function getOffers(store) {
  const url = `/gsmPacks/ooredoo`;
  const { data } = await apiCall({
    method: 'get',
    url,
  });

  if (data?.length > 0) {
    store.dispatch({
      type: GET_LIST_OFFER,
      payload: {
        data: data,
      },
    });
  } else
    store.dispatch({
      type: GET_LIST_OFFER,
      payload: {
        data: [],
      },
    });
  return data;
}
