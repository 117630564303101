import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Formik, Form, Field } from 'formik';
import CQbox from '../utils/CQbox';
import CQlabel from '../utils/CQlabel';
import CQbuttonGreen from '../utils/buttons/CQbuttonGreen';
import CQinputField from '../utils/inputs/CQinputField';
import CQtypographyBlue from '../utils/typography/CQtypographyBlue';
import CQooredooLogo from '../utils/CQooredooLogo';
import CQtypographyPrice from '../utils/typography/CQtypographyPrice';
import toast from '../../utils/toast';
import { useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createPayment } from '../../actions/create-payment';

const OffersForm = ({ offers }) => {
  const store = useStore();
  const navigate = useNavigate();

  const [total, setTotal] = useState(offers[0].tariff[0].price);
  const [indexOffer, setIndexOffer] = useState(0);
  const [indexPacks, setIndexPacks] = useState(0);
  const [number, setNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    navigate('/confirmation');
    try {
      setLoading(true);
      const result = await createPayment(values, store);
      if (result.status === 200) {
        setLoading(false);
        navigate('/confirmation');
      }
    } catch (error) {
      setLoading(false);
      if (error?.response)
        toast.showError(store, error?.response?.data?.message || 'error');
    }
  };

  const initialValues = {
    offerId: offers[0].id,
    studentsNumber: 1,
  };
  const calTotal = (number, subscription) => {
    if (number <= 3) {
      setNumber(number);
      setIndexPacks(number - 1);
      let total = offers[indexOffer].tariff[number - 1].price * number;

      setTotal(total);
      switch (subscription) {
        case 'dailySubscription':
          setTotal(number * 2.5);
          break;
        case 'weeklySubscription':
          setTotal(number * 4.5);
          break;
        case 'monthlySubscription':
          setTotal(number * 8.5);
          break;
      }
    } else {
      toast.showError(store, 'error');
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        handleChange,
        values,
      }) => (
        <Form>
          {offers &&
            offers.length > 0 &&
            offers.map((offer, index) => (
              <CQbox key={offer.id}>
                <Stack direction="row" className="center-stack">
                  <Box
                    width={{ xs: '136px', sm: '210px' }}
                    className="label-center"
                  >
                    <Field
                      onChange={(e) => {
                        setFieldValue('offerId', parseInt(e.target.value));
                        setTotal(number * offer.tariff[indexPacks].price);
                        setIndexOffer(index);
                      }}
                      type="radio"
                      name="offerId"
                      id="daily_subscription"
                      value={offer.id}
                      className="form-check-input"
                      checked={index === indexOffer}
                    />

                    <CQlabel htmlFor="daily_subscription">
                      {offer.title}
                    </CQlabel>
                  </Box>
                  <CQooredooLogo />
                  <CQtypographyPrice>
                    {offer.tariff[indexPacks].price} د.ت
                  </CQtypographyPrice>
                </Stack>
              </CQbox>
            ))}

          <CQbox>
            <Stack direction="row" className="center-stack">
              <CQtypographyBlue
                component="p"
                fontSize={{ xs: '14px !important', sm: '1.875rem !important' }}
                color="CustomBlue.light"
              >
                عدد الأطفال المنتفعين بالعرض:
              </CQtypographyBlue>
              <CQinputField fieldName="studentsNumber" calTotal={calTotal} />
            </Stack>
          </CQbox>

          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <CQbox>
              <CQtypographyPrice
                component="p"
                color="CustomBlue.dark"
                paddingX={5}
              >
                الثمن الجملي: {parseFloat(total).toFixed(2)} د.ت
              </CQtypographyPrice>
            </CQbox>
          </Box>
          <Box textAlign="center" mt={4}>
            <CQbuttonGreen
              className={loading ? 'buttonload' : ''}
              type="submit"
              variant="contained"
              onClick={handleSubmit}
              disabled={loading}
            >
              موافق
              {loading ? <i className="fa fa-refresh fa-spin"></i> : ''}
            </CQbuttonGreen>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default OffersForm;
