import { apiCall } from '../utils/helpers';
import { GET_LIST_STUDENT } from '../redux/reducers/student';

export async function getStudents(store) {
  const url = `/students`;
  const { data } = await apiCall({
    method: 'get',
    url,
  });

  if (data?.length > 0) {
    store.dispatch({
      type: GET_LIST_STUDENT,
      payload: {
        data: data,
      },
    });
  } else
    store.dispatch({
      type: GET_LIST_STUDENT,
      payload: {
        data: [],
      },
    });
  return data;
}
